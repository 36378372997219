* {
  font-family: "PoppinsRegular" !important;
}
 
.morganLogo {
  cursor: pointer;
}

.bodyContainer {
  background-image: url("https://resources.swmc.com/angel-ai/page-bg.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 3rem;
  color: #fff;
  margin-top: -2rem;
}
